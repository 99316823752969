body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--amplify-components-text-color);
}

a {
  color: #008cd3;
  text-decoration: none;
}
a:hover {
  color: var(--amplify-colors-brand-primary-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.table dl {
  padding: 16px;
  background: var(--amplify-colors-background-info);
  margin: 0;
}

table.table dl dt {
  font-weight: 700;
}

table.table dl dd {
  margin: 0;
}

button.myedu-btn.btn-default {
  background: #00008a;
  border-color: #00008a;
  color: var(--amplify-colors-white);
}

button.myedu-btn.btn-primary {
  background: #000;
  border-color: #000;
  color: #fff;
}

button.myedu-btn.btn-success {
  background: #00a800;
  border-color: #00a800;
  color: #fff;
}

button.myedu-btn.btn-warning {
  background: #f8f800;
  border-color: #f8f800;
  color: #fff;
}


button.myedu-btn.btn-destructive {
  background: #a10000;
  border-color: #a10000;
  color: #fff;
}

.myedu-emphasize-success {
  color: #00a800;
}
.myedu-emphasize-warning {
  color: #f8f800;
}
.myedu-emphasize-error {
  color: #a10000;
}