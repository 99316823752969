.container {
    padding: 32px 64px;
    width: 90vw;
    margin: 0 auto;
    max-width: 1800px;
}

@media screen and (max-width: 767px) {
    .container {
        width: 100%;
        padding: 16px;
    }
}