html {
    scroll-padding-top: 80px;
    scroll-behavior: smooth;
}

body {
    letter-spacing: 0.05em;
    scroll-behavior: smooth;
}

.contentView {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    padding-top: 80px;
}