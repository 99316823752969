.footer,
.footer .footerText,
.footer > .footerText,
.footerText {
    background: #000;
    color: white;
}

.footerText a,
.footerText > a {
    font-weight: 700;
    color: white;
}

.footerText span,
.footerText > span {
    font-weight: 400;
    color: white;
}