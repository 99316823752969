.mainGrid {
    padding: 64px 0 0 0;
    text-align: left;
}

.titleViewWrapper,
.contentViewWrapper,
.itemsCollectionViewWrapper {
    padding: 0 64px 64px 64px;
}

.codeWrapper {
    padding: 16px;
    background: var(--amplify-colors-background-error);
}

.codeWrapper code {
    padding: 1em;
}

.btnContainer {
    padding: 64px 0;
}

.backBtn {
    color: #fff;
    background-color: #000;
}

@media screen and (max-width: 767px) {
    .btnContainer,
    .mainGrid {
        padding: 32px;
    }

    .titleViewWrapper,
    .contentViewWrapper,
    .itemsCollectionViewWrapper {
        padding: 0 32px 32px 32px;
    }
}
